import { gql } from '@apollo/client';
export default gql`
  query MerchantCampaignDetail($id: uuid!, $additionalEnable: Boolean! = false) {
    campaign_by_pk(id: $id) {
      brand_name
      client_profile_id
      client_profile {
        company_name
        user {
          given_name
          family_name
          picture {
            url
            id
          }
        }
        logo_file {
          url
        }
      }
      created_at
      budget_usd_cents
      display_currency
      budget_currency {
        symbol
        fraction_size
        template
      }
      description
      end_date
      website
      id
      image_url
      images {
        file_metadata {
          id
          url
        }
      }
      name
      product_name
      recruitment_end_date
      start_date
      status
      budget_cash_usd_cents
      budget_pik_usd_cents
      tranches {
        id
        name
        mapping_tranche_languages {
          language_code
          language {
            title
          }
        }
        product_categories {
          product_category {
            name
          }
          category_code
        }
        images {
          file_metadata {
            url
          }
        }
        min_follower_size
        max_follower_size
        social_platforms {
          category_code
          social_platform {
            name
            image_icon_path
          }
        }
        genders {
          category_code
          gender {
            code
            description
          }
        }
        age_groups {
          category_code
          age_category {
            name
            min_value
            max_value
          }
        }
        number_kols
        country_code
        country {
          name
          code
        }
        cash_usd_cents
        product_value_usd_cents
        product_description
        deliverable_specs {
          id
          quantity
          content_type_code
          content_type {
            name
          }
          requirement_description
          due_date
          title
        }
        requirements
        work_contracts {
          id
          created_at
          additional_pay_usd_cents @include(if: $additionalEnable)
          additional_pay_reason @include(if: $additionalEnable)
          kol_profile {
            id
            gender
            first_name
            last_name
            follower_size
            engagement_rate
            date_of_birth
            created_at
            cpe_rate
            country_code
            email
            kol_file_metadata {
              type
              file_metadata {
                url
                id
              }
              updated_at
            }
            bio
            country_category {
              code
              name
            }
            is_active
            is_approved
            min_rate_photo_usd_cents
            min_rate_video_usd_cents
            past_brands_collaboration
            product_categories {
              product_category {
                code
                name
              }
            }
            social_platforms {
              social_platform_category {
                code
                name
                image_icon_path
              }
              platform_username
              platform_user_id
            }
            user {
              id
              given_name
              family_name
              picture {
                id
                url
                content_type
                size_bytes
              }
            }
          }
          status {
            code
            description
          }
          tranche {
            id
            name
          }
          work_items {
            id
            link
            status {
              code
              description
            }
            deliverable_spec {
              id
              content_type {
                code
                name
              }
              title
              quantity
              requirement_description
              due_date
            }
          }
        }
      }
    }
  }
`;
