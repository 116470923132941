import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Collapsible from 'react-collapsible';
import { format } from 'date-fns';
import { useHistory, generatePath } from 'react-router-dom';
import * as R from 'ramda';
import qs from 'query-string';

// common
import DatepickerRange from '../../../commons/DatepickerRange';
import Button from '../../../commons/Button';
import DatePicker from '../../../commons/Datepicker';
import TextFieldArea from '../../../commons/TextFieldArea';
import ContainerScroll from '../../../commons/ContainerScroll';
import LabelStyled from '../../../commons/Label';

// styled
import GridStyled from '../../../styled/GridStyled';
import RowStyled from '../../../styled/RowStyled';
//css
import '../../../../style/main.css';

import useCampaign from '../../../../hooks/useCampaign';
import useMobile from '../../../../hooks/useMobile';
import useConvertCurrency from '../../../../hooks/useConvertCurrency';
import { ROUTES, FORMAT_DATE_DEFAULT } from '../../../../utils/constants';
import { fortmatCurrencyTemplate } from '../../../../utils/formatCurrency';
import { mobileQueryString } from '../../../../utils/responsive';

// assets
import { ReactComponent as ArrowDownSVG } from '../../../../assets/svg/drop_down_bottom.svg';
import { ReactComponent as ErrorSVG } from '../../../../assets/svg/error_black_24dp.svg';

import CampaignIconSocial from '../../CampaignIconSocial';
import CampaignPreviewHeader from './CampaignPreviewMobileHeader';
import InsufficientCreditPopup from '../../../popUp/topUp/insufficientCredit/InsufficientCreditPopup';
import PublishNavbar from './PublishNavbar';
import StripeForm from '../../../popUp/stripe/StripeForm';
import CampaignPublishSuccess from '../../../popUp/campaignPublish/campaignPublishSuccess/CampaignPublishSuccessPopUp';
import CampaignPublishFailed from '../../../popUp/campaignPublish/campaignPulishFail/CampaignPublishPopUpFail';
import ConfirmPopUp from '../../../popUp/confirm/ConfirmPopUp';
import Stripe from '../../../stripe/Stripe';
import { FEATURE_KEY, useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import useToastError from '../../../../hooks/useToastError';

import GET_PREVIEW_AMOUNT_CAMPAIGN from '../../../../queries/campaignCreation/getPublishAmountCampaign';
import { useLazyQuery, useQuery } from '@apollo/client';

function CampaignPreview({
  handlePublishCampaign,
  onEditCampaign,
  handleUpdateCampaign,
  isLoading,
  user,
  onRefreshWallet,
  backToStep,
  campaignDetail,
  isSuccessPublish,
  isFailedPublish,
  onCloseFailedPublish,
}) {
  const [indexGroup, setIndexGroup] = useState(0);
  const [totalKOLs, setTotalKOLs] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [totalPIK, setTotalPIK] = useState(0);
  const [isShowCreditTopUp, setIsShowCreditTopUp] = useState(false);
  const [isShowStripeForm, setIsShowStripeForm] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isLoadingPublish, setIsLoadingPublish] = useState(false);
  const [amount, setAmount] = useState(0);
  const [group, setGroup] = useState<any>([]);
  const { isFeatureFlagEnable, handleRefreshConfig, loading } = useFeatureFlag();
  const [getPreviewAmount, { data: previewResponse }] = useLazyQuery(GET_PREVIEW_AMOUNT_CAMPAIGN);
  const requireAmount = (previewResponse?.getPreviewAmountInCentsToPublishCampaign?.result || 0) / 100;

  const isMobile = useMobile();
  const history = useHistory();
  const { register, setValue } = useForm();
  const { onSetDraftCampaignId, onGetCampaignById, campaignDetail: formData } = useCampaign();
  const { exchangeRate } = useConvertCurrency();
  const query = qs.parse(history.location.search) as any;

  const { toastError } = useToastError();

  useEffect(() => {
    if (!campaignDetail || !campaignDetail?.id) return;
    getPreviewAmount({ variables: { campaignId: campaignDetail?.id } });
  }, [campaignDetail]);

  const owner = useMemo(() => {
    if (!campaignDetail || !campaignDetail?.client_profile) return {};
    return {
      name: campaignDetail?.client_profile?.company_name,
      avatar: campaignDetail?.client_profile?.logo_file?.url || campaignDetail?.image_url,
      title: campaignDetail?.name || '',
      status: campaignDetail?.status.toLowerCase(),
    };
  }, [campaignDetail]);

  useEffect(() => {
    if (loading) {
      handleRefreshConfig();
    }
  }, [loading, handleRefreshConfig]);

  useEffect(() => {
    if (!user?.profile?.wallet) {
      onRefreshWallet();
    }
  }, [onRefreshWallet, user?.profile?.wallet]);

  useEffect(() => {
    if (R.is(String, query.draftId)) {
      onGetCampaignById(query.draftId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.draftId]);

  useEffect(() => {
    if (!formData) return;
    handleUpdateCampaign(formData);
    setGroup(formData?.tranches);
    const { number_kols } = formData?.tranches.reduce(
      (total, curr) => {
        return {
          number_kols: total.number_kols + parseInt(curr.number_kols),
        };
      },
      {
        number_kols: 0,
      },
    );
    setTotalKOLs(number_kols);
    setTotalPIK(formData?.budget_pik_usd_cents);
    setTotalCash(formData?.budget_cash_usd_cents);
    setValue('campaignPeriod', [
      formData.start_date ? new Date(formData.start_date) : null,
      formData.end_date ? new Date(formData.end_date) : null,
    ]);
    setValue('recruitment_end_date', formData.recruitment_end_date ? new Date(formData.recruitment_end_date) : null);
    setValue('description', formData?.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const listImage = useMemo(() => {
    if (formData?.avatar) {
      const url = URL.createObjectURL(formData.avatar);
      return [{ src: url }];
    }
    return [];
  }, [formData?.avatar]);

  const amountUSD = useMemo(() => {
    return Number((amount / exchangeRate).toFixed(2));
  }, [amount]);

  const requireAmountDisplay = useMemo(() => {
    return Number((requireAmount * exchangeRate).toFixed(user?.profile?.default_currency?.fraction_size ?? 0));
  }, [requireAmount, user?.profile?.default_currency?.fraction_size, exchangeRate]);

  const walletValue = useMemo(() => {
    return Number(((user?.profile?.wallet || 0) / 100).toFixed(2));
  }, [user?.profile?.wallet]);

  const walletDisplayValue = useMemo(() => {
    return Number((walletValue * exchangeRate).toFixed(user?.profile?.default_currency?.fraction_size ?? 0));
  }, [walletValue, user?.profile?.default_currency?.fraction_size, exchangeRate]);

  const handleEditCampaign = () => {
    onSetDraftCampaignId(formData?.id);
    const path = generatePath(ROUTES.campaignsCreate);
    history.push(path + `?draftId=${formData?.id}`);
    onEditCampaign();
  };

  const handleShowPopUp = useCallback(async () => {
    const startDate = new Date(formData?.start_date);
    if (startDate.getTime() < Date.now()) {
      toastError('Start date cannot be in the past');
      return;
    }
    if (walletValue >= requireAmount) {
      setIsShowConfirm(true);
      return;
    }
    await handleRefreshConfig();
    if (isFeatureFlagEnable(FEATURE_KEY.stripe) !== true) {
      alert('use ops-tool to add credit to Merchant wallet');
      return;
    }
    if (walletValue < requireAmount) {
      setIsShowCreditTopUp(true);
      return;
    }
    setIsShowConfirm(true);
  }, [requireAmount, walletValue, loading]);

  const handleOpenStripeForm = useCallback(() => {
    setIsShowCreditTopUp(false);
    if (walletValue < requireAmount) {
      setIsShowStripeForm(true);
      return;
    }
  }, [requireAmount, walletValue]);

  const handlePublish = useCallback(async () => {
    setIsLoadingPublish(true);
    await handlePublishCampaign();
    setIsLoadingPublish(false);
    setIsShowConfirm(false);
  }, [handlePublishCampaign]);

  const handleCloseStripeForm = useCallback((status) => {
    if (status) {
      setIsShowStripeForm(false);
      setIsShowConfirm(true);
    }
  }, []);

  return (
    <>
      {isMobile && (
        <>
          <CampaignPreviewHeader onGoBack={backToStep} owner={owner} />
          <PublishNavbar handleEditCampaign={handleEditCampaign} isLoading={isLoading} handlePublishCampaign={handleShowPopUp} />
        </>
      )}

      {isShowCreditTopUp && (
        <InsufficientCreditPopup
          walletDisplay={walletDisplayValue}
          walletUSD={walletValue}
          display_currency={user?.profile?.default_currency?.code ?? 'USD'}
          requireAmount={requireAmountDisplay}
          requireAmountUSD={requireAmount}
          onClose={() => setIsShowCreditTopUp(false)}
          onSubmit={handleOpenStripeForm}
          showAdditionalMessage={false}
          amount={amount}
          handleAmount={setAmount}
        />
      )}

      {isSuccessPublish && (
        <CampaignPublishSuccess
          balance={walletDisplayValue}
          balanceUSD={walletValue}
          onRefresh={onRefreshWallet}
          currency={user?.profile?.default_currency?.code ?? 'USD'}
        />
      )}

      {isFailedPublish && (
        <CampaignPublishFailed
          balanceUSD={walletValue}
          balance={walletDisplayValue}
          onSubmit={handlePublish}
          isLoadingPublish={isLoadingPublish}
          onClose={onCloseFailedPublish}
          currency={user?.profile?.default_currency?.code ?? 'USD'}
        />
      )}

      {isShowConfirm && (
        <ConfirmPopUp
          handlePublish={() => {
            setIsShowConfirm(false);
            handlePublish();
          }}
          confirmTitle={'CONFIRM & PUBLISH'}
          isLoadingPublish={isLoadingPublish}
          requireAmount={requireAmountDisplay}
          requireAmountUSD={requireAmount}
          onClose={() => setIsShowConfirm(false)}
          currency={user?.profile?.default_currency?.code ?? 'USD'}
        />
      )}

      {isShowStripeForm && amountUSD > 0 && (
        <Stripe>
          <StripeForm
            onClose={() => setIsShowStripeForm(false)}
            value={amountUSD}
            user={user}
            handlePublish={handleCloseStripeForm}
            onRefreshWallet={onRefreshWallet}
          />
        </Stripe>
      )}

      <Form>
        <CampaignHeader justifyContent="space-between" alignItems="baseline">
          <RowStyled>
            <CampaignTitle>Campaign Preview</CampaignTitle>
          </RowStyled>
          <RowStyled style={{ width: 'fit-content' }}>
            <Button type="button" width="110px" variant="secondary" label="Edit" onClick={handleEditCampaign} />
            <Button type="button" width="149px" label={isLoading ? 'WAITING' : 'Publish'} onClick={handleShowPopUp} />
          </RowStyled>
        </CampaignHeader>
        {!isMobile && <LineStyled mb="0" />}

        <CustomContainerScroll>
          <ListCampaignStyled>
            <CampaignContainer pt="0" maxWidth="100%">
              {Array.isArray(listImage) && listImage.length > 0 && (
                <GridStyled>
                  <RowStyled>
                    <GalleryImage>
                      {listImage.map((image, idx) => {
                        return <Image key={idx} src={image.src} />;
                      })}
                    </GalleryImage>
                  </RowStyled>
                </GridStyled>
              )}
              <GridStyled>
                <RowCustomStyled mb="30px">
                  <FlexBox>
                    <ListCampaignInfo mr="72px">
                      <LabelLightColor>Product</LabelLightColor>
                      <LabelBoldColor>{formData?.product_name}</LabelBoldColor>
                    </ListCampaignInfo>
                    <ListCampaignInfo>
                      <LabelLightColor>KOLs (qyt of kols)</LabelLightColor>
                      <LabelBoldColor>{totalKOLs}</LabelBoldColor>
                    </ListCampaignInfo>
                  </FlexBox>
                  <LineVertical />
                  <FlexBox>
                    <ListCampaignInfo mr="38.79px">
                      <LabelLightColor>
                        <NoteBudgetIcon>
                          Total Budget
                          <ErrorSVG />
                        </NoteBudgetIcon>
                      </LabelLightColor>
                      <LabelBoldColor>
                        {fortmatCurrencyTemplate(
                          ((formData?.budget_usd_cents || 0) / 100) * exchangeRate,
                          user?.profile?.default_currency?.template,
                          user?.profile?.default_currency?.symbol,
                          user?.profile?.default_currency?.fraction_size,
                        )}
                      </LabelBoldColor>
                    </ListCampaignInfo>
                    <Flex>
                      <ListCampaignInfo mr="32px">
                        <LabelLightColor>Total Cash</LabelLightColor>
                        <LabelBoldColor>
                          {fortmatCurrencyTemplate(
                            (totalCash / 100) * exchangeRate,
                            user?.profile?.default_currency?.template,
                            user?.profile?.default_currency?.symbol,
                            user?.profile?.default_currency?.fraction_size,
                          )}
                        </LabelBoldColor>
                      </ListCampaignInfo>
                      <ListCampaignInfo>
                        <LabelLightColor>Total PIK</LabelLightColor>
                        <LabelBoldColor>
                          {fortmatCurrencyTemplate(
                            (totalPIK / 100) * exchangeRate,
                            user?.profile?.default_currency?.template,
                            user?.profile?.default_currency?.symbol,
                            user?.profile?.default_currency?.fraction_size,
                          )}
                        </LabelBoldColor>
                      </ListCampaignInfo>
                    </Flex>
                  </FlexBox>
                </RowCustomStyled>
              </GridStyled>
            </CampaignContainer>
            <LineStyled />
            <CampaignContainer maxWidth="700px">
              <GridStyled>
                <RowStyled>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      disabled
                      // name="recruitmentDeadline"
                      label="Recruitment Deadline"
                      placeholder=""
                      {...register('recruitment_end_date', {
                        required: {
                          value: true,
                          message: 'Recruitment Deadline is required',
                        },
                      })}
                    />
                  </MuiPickersUtilsProvider>
                </RowStyled>
                <RowStyled>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatepickerRange
                      disabled
                      // name="campaignPeriod"
                      label="Campaign Period"
                      placeholder=""
                      {...register('campaignPeriod', {
                        validate: (value) => {
                          return value.filter(Boolean).length === 0 ? 'Campaign Period is required' : value;
                        },
                      })}
                    />
                  </MuiPickersUtilsProvider>
                </RowStyled>
              </GridStyled>
              <GridStyled>
                <RowStyled>
                  <TextFieldArea
                    {...register('description', {
                      required: {
                        value: true,
                        message: 'Campaign Name is required',
                      },
                    })}
                    name="description"
                    label="Campaign Description"
                    disabled
                  />
                </RowStyled>
              </GridStyled>
            </CampaignContainer>
            <LineStyled />
            <TitleStyled mb="3px">Kols Requirements</TitleStyled>
            <KolsContainer>
              {group.map((data, idx) => (
                <CollapsibleGroup
                  open={idx === indexGroup}
                  trigger={
                    <CollapsibleTitle className="Collapsible__title">
                      <GroupTitle>{data?.name}</GroupTitle>
                      <ArrowDownSVG className="Collapsible__arrow" />
                    </CollapsibleTitle>
                  }
                  idx={idx}
                  length={group.length}
                  key={idx}
                  handleTriggerClick={() => {
                    setIndexGroup(idx === indexGroup ? null : idx);
                  }}
                >
                  <KolsRequirementGroup
                    formData={formData}
                    data={data}
                    idx={idx}
                    length={group.length}
                    exchangeRate={exchangeRate}
                    user={user}
                  />
                </CollapsibleGroup>
              ))}
            </KolsContainer>
          </ListCampaignStyled>
        </CustomContainerScroll>
      </Form>
    </>
  );
}
const KolsRequirementGroup = (props) => {
  const { data = {}, formData, idx, length, exchangeRate, user } = props;
  const { register, setValue } = useForm();
  const isMobile = useMobile();

  useEffect(() => {
    if (!formData) return;
    setValue('campaignPeriod', [
      formData.start_date ? new Date(formData.start_date) : null,
      formData.end_date ? new Date(formData.end_date) : null,
    ]);
  });

  return (
    <KolsRequirementItem>
      <TitleStyled mb="7px">KOL Affinity</TitleStyled>
      <GridStyled>
        <RowCustomStyled>
          <ListTag>
            {data.product_categories.map((tag, idx) => (
              <TagItem key={idx}>{tag?.product_category?.name}</TagItem>
            ))}
          </ListTag>
        </RowCustomStyled>
      </GridStyled>
      <GridStyled>
        <RowCustomStyled>
          <ListCampaignInfo mr="34px">
            <LabelLightColor>Min Followers</LabelLightColor>
            <LabelBoldColor>{data?.min_follower_size || 0}</LabelBoldColor>
          </ListCampaignInfo>
          <ListCampaignInfo mr="0">
            <LabelLightColor>Max Followers</LabelLightColor>
            <LabelBoldColor>{data?.max_follower_size || 0}</LabelBoldColor>
          </ListCampaignInfo>
        </RowCustomStyled>
      </GridStyled>
      <GridStyled>
        <RowCustomStyled>
          <TitleNote>
            If 2 or more platforms are selected, the follower size will refer to the total followers aggregated from the selected
            platforms.
          </TitleNote>
        </RowCustomStyled>
      </GridStyled>
      <GridStyled>
        <RowCustomStyled width={isMobile ? '100%' : '335px'} mb="0px">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatepickerRange
              disabled
              // name="campaignPeriod"
              label="Campaign Period"
              placeholder=""
              {...register('campaignPeriod', {
                validate: (value) => {
                  return value.filter(Boolean).length === 0 ? 'Campaign Period is required' : value;
                },
              })}
            />
          </MuiPickersUtilsProvider>
        </RowCustomStyled>
      </GridStyled>
      <LineStyled />
      <TitleStyled mb="13px">Remuneration per KOL</TitleStyled>
      <GridStyled>
        <RowCustomStyled mb="30px">
          <ListCampaignInfo mr="76px">
            <LabelLightColor>Cash</LabelLightColor>
            <LabelBoldColor>
              {fortmatCurrencyTemplate(
                (data?.cash_usd_cents / 100) * exchangeRate,
                user?.profile?.default_currency?.template,
                user?.profile?.default_currency?.symbol,
                user?.profile?.default_currency?.fraction_size,
              )}
            </LabelBoldColor>
          </ListCampaignInfo>
          <ListCampaignInfo mr="0">
            <LabelLightColor>PIK VALUE</LabelLightColor>
            <LabelBoldColor>
              {fortmatCurrencyTemplate(
                (data?.product_value_usd_cents / 100) * exchangeRate,
                user?.profile?.default_currency?.template,
                user?.profile?.default_currency?.symbol,
                user?.profile?.default_currency?.fraction_size,
              )}
            </LabelBoldColor>
          </ListCampaignInfo>
        </RowCustomStyled>
      </GridStyled>
      <GridStyled>
        <RowCustomStyled>
          <ListCampaignInfo>
            <LabelLightColor>PIK Description</LabelLightColor>
            <TextContent>{data?.product_description}</TextContent>
          </ListCampaignInfo>
        </RowCustomStyled>
      </GridStyled>
      <LineStyled />
      <GridStyled>
        <KolRowCustom mb="16px">
          <ListCampaignInfo mr="60px">
            <LabelLightColor>No of KOLs</LabelLightColor>
            <LabelMediumColor>{data?.number_kols}</LabelMediumColor>
          </ListCampaignInfo>
          <ListCampaignInfo mr="60px">
            <LabelLightColor>Country</LabelLightColor>
            <LabelMediumColor>{data?.country?.name}</LabelMediumColor>
          </ListCampaignInfo>
          <ListCampaignInfo mr="101px">
            <LabelLightColor>Platform</LabelLightColor>
            <LabelMediumColor>
              <CampaignIconSocial social_platforms={data.social_platforms} />
            </LabelMediumColor>
          </ListCampaignInfo>
          <ListCampaignInfo mr="70px">
            <LabelLightColor>Age Group</LabelLightColor>
            <AgeGroup>
              {data?.age_groups.map((age, idx) => {
                return <AgeItem key={idx}>{age?.age_category?.name}</AgeItem>;
              })}
            </AgeGroup>
          </ListCampaignInfo>
          <ListCampaignInfo mr="0px">
            <LabelLightColor>Gender</LabelLightColor>
            {data?.genders.map((g, idx) => {
              return <LabelMediumColor key={idx}>{g?.gender?.description}</LabelMediumColor>;
            })}
          </ListCampaignInfo>
        </KolRowCustom>
      </GridStyled>
      <LineStyled />
      <TitleStyled mb="13px">Deliverables</TitleStyled>
      <GridStyled>
        <RowCustomStyled mb="18px">
          <DeliverableContainer>
            {data?.deliverable_specs.map((deliverable, idx) => {
              return (
                <DeliverableItem key={idx}>
                  <span className="label">{deliverable?.requirement_description}</span>
                  <div className="">
                    <span className="date-title">Due Date</span>
                    <span className="date-time">{format(new Date(deliverable?.due_date), FORMAT_DATE_DEFAULT)}</span>
                  </div>
                </DeliverableItem>
              );
            })}
          </DeliverableContainer>
        </RowCustomStyled>
      </GridStyled>
      <TitleStyled mb="10px">Other Requirements</TitleStyled>
      <GridStyled>
        <RowCustomStyled mb="30px">
          <TextContent>{data?.requirements}</TextContent>
        </RowCustomStyled>
      </GridStyled>
      {idx !== length - 1 && <LineStyled mb="0" />}
    </KolsRequirementItem>
  );
};
export default CampaignPreview;

const CampaignTitle = styled.h3`
  margin: 0;
  font: normal normal 500 16px/19px Helvetica Neue;
  letter-spacing: 0px;
  color: #5770c6;
`;
const Form = styled.form`
  width: 100%;
  padding-bottom: 50px;
`;
const CampaignHeader = styled<any>(GridStyled)`
  margin-bottom: 31px;

  ${mobileQueryString} {
    display: none;
  }
`;

const CampaignContainer = styled.div<any>`
  ${(props) =>
    !!props.pt &&
    css`
      padding-top: ${props.pt || 0};
    `};
  ${(props) =>
    !!props.maxWidth &&
    css`
      max-width: ${props.maxWidth || '700px'};
    `};
`;
const KolsContainer = styled.div<any>`
  max-width: 100%;
`;
const GalleryImage = styled.div<any>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-right: -11.7px;
`;
const Image = styled.img`
  max-width: 100%;
  width: 90px;
  object-fit: cover;
  padding-right: 11.6px;
  margin-bottom: 10px;
  display: block;
`;

const ListCampaignInfo = styled.div<any>`
  ${(props) =>
    !!props.mr &&
    css`
      margin-right: ${props.mr || 0};
    `};
`;

const LabelLightColor = styled<any>(LabelStyled)`
  color: #949494;
  font: normal normal 500 13px/16px 'Helvetica Neue';
  margin-bottom: 4px;
  cursor: default;
`;

const LabelBoldColor = styled<any>(LabelStyled)`
  color: #0b0b0b;
  font: normal normal bold 16px/19px 'Helvetica Neue';
  margin-bottom: 0px;
  cursor: default;
`;

const LabelMediumColor = styled<any>(LabelStyled)`
  color: #3e3e3e;
  font: normal normal 500 16px/19px 'Helvetica Neue';
  margin-bottom: 0px;
  svg {
    width: 14px;
    height: 14px;
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
  cursor: default;
`;

const RowCustomStyled = styled<any>(RowStyled)`
  margin-bottom: ${(props) => props.mb || '18px'};
  width: ${(props) => props.width || '100%'};
`;

const KolRowCustom = styled<any>(RowCustomStyled)`
  ${mobileQueryString} {
    flex-direction: column;
    margin-bottom: 0;

    & > div {
      display: grid;
      grid-auto-flow: column;
      padding: 0 0 15px;
      column-gap: 30px;
      justify-content: space-between;
      border-bottom: 1px solid #eeeeee;
      margin-right: 0;

      &:not(:first-child) {
        margin-top: 15px;
      }

      & > label {
        width: auto;
      }
    }
  }
`;

const TitleStyled = styled.p<any>`
  color: #5770c6;
  font: normal normal 500 16px/19px 'Helvetica Neue';
  margin: 0;
  margin-bottom: ${(props) => props.mb || '18px'};
  cursor: default;
`;
const LineStyled = styled.div<any>`
  width: 100%;
  position: relative;
  height: 1px;
  display: block;
  margin-bottom: ${(props) => props.mb || '18px'};
  &::after {
    position: absolute;
    display: block;
    content: '';
    top: 0px;
    height: 1px;
    width: 100%;
    background: #eeeeee;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const CollapsibleTitle = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 19.5px;
  cursor: default;
  font-family: 'Helvetica Neue';
  background-color: rgba(238, 238, 238, 0.25);
`;

const CollapsibleGroup = styled<any>(Collapsible)`
  & svg.Collapsible__arrow {
    transition: all 0.5s;
  }

  & .is-closed {
    span {
      color: #949494 !important;
      font: normal normal normal 14px/16px 'Helvetica Neue';
    }
    & svg.Collapsible__arrow {
      transform: rotate(-90deg);
    }
    & .Collapsible__title {
      background-color: transparent;
      ${(props) =>
        props.idx !== props.length - 1 &&
        css`
          border-bottom: 1px solid #eeeeee;
        `}
    }
  }
  & .is-open {
    color: #0b0b0b;
    font: normal normal 500 14px/17px 'Helvetica Neue';
    & svg.Collapsible__arrow {
      transform: rotate(360deg);
    }
    & .Collapsible__title {
      border-bottom: none;
    }
  }
`;
const KolsRequirementItem = styled.div<any>`
  padding-left: 20px;
  padding-right: 19.5px;
  background-color: rgba(238, 238, 238, 0.25);
`;
const GroupTitle = styled.span<any>`
  font: normal normal 500 14px/17px Helvetica Neue;
  color: #0b0b0b;
`;

const ListTag = styled.div<any>`
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;
`;
const TagItem = styled.span<any>`
  display: block;
  font: normal normal normal 13px/15px Helvetica Neue;
  color: #3e3e3e;
  padding: 7px 9px 8px 9px;
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 10px;
`;
const TitleNote = styled.p<any>`
  color: #949494;
  font: normal normal normal 12px/14px Helvetica Neue;
  margin: 0;
  padding: 0;
`;
const TextContent = styled.p<any>`
  margin: 0;
  font: normal normal normal 14px/16px Helvetica Neue;
  color: #3e3e3e;
`;
const AgeGroup = styled.div<any>`
  display: flex;
  justify-content: flex-end;
  margin-right: -20px;

  ${mobileQueryString} {
    flex-wrap: wrap;
  }
`;
const AgeItem = styled.div<any>`
  padding-right: 20px;
  color: #3e3e3e;
  font: normal normal 500 16px/19px Helvetica Neue;
`;
const DeliverableContainer = styled.div<any>`
  width: 100%;
`;
const DeliverableItem = styled.div<any>`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
  &:not(:first-child) {
    margin-top: 11px;
  }
  & .label {
    font: normal normal normal 13px/15px Helvetica Neue;
    color: #3e3e3e;
    word-break: break-word;
  }
  & .date-title {
    font: normal normal 500 12px/15px Helvetica Neue;
    color: #5770c6;
    display: block;
    text-align: right;
  }
  & .date-time {
    font: normal normal bold 12px/15px Helvetica Neue;
    color: #0b0b0b;
    text-align: right;
  }
`;

const NoteBudgetIcon = styled.div<any>`
  position: relative;
  svg {
    width: 11px;
    height: 11px;
    fill: none;
    stroke: #5770c6;
    margin-left: 6px;
  }
  &:hover::after {
    display: block;
  }
  &::after {
    content: 'Cash + PIK \\A e.g. if current currency is MYR: USD1000 * 4.23 = MYR4230 ';
    position: absolute;
    display: none;
    background-color: #eeeeee;
    color: #3e3e3e;
    font: normal normal normal 10px/12px Helvetica Neue;
    width: 268px;
    left: 84%;
    top: -100%;
    transform: translate(0, -11.79px);
    padding: 2px 4px 3px;
    border-radius: 4px;
    white-space: pre;
  }
`;
const LineVertical = styled.div<any>`
  width: 1px;
  height: 25px;
  margin-left: 16px;
  margin-right: 22px;
  background-color: #eeeeee;

  ${mobileQueryString} {
    height: 114px;
  }
`;

const ListCampaignStyled = styled.div<any>``;

const CustomContainerScroll = styled<any>(ContainerScroll)`
  padding-top: 30px;

  ${mobileQueryString} {
    padding: 30px 20px;
  }
`;

const Flex = styled.div<any>`
  display: flex;
`;

const FlexBox = styled.div<any>`
  display: grid;
  grid-auto-flow: column;

  ${mobileQueryString} {
    grid-auto-flow: unset;
    row-gap: 20px;
  }
`;
